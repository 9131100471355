import React from 'react'
import img1 from '../../assets/img/Homepage/interface.png'
import img2 from '../../assets/img/Homepage/collaborate.png'
export default function StepsSection() {
  return (
    <div className='bg-[#0f172a]'>
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-16 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className=" inline-block text-base font-semibold leading-7 text-blue-100 bg-blue-600 px-3 rounded-xl uppercase mb-4 lg:mb-8">
          How It Works
          </p>
        </div>
        <h2 className="max-w-2xl mb-6 text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
        
        <span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-blue-300 to-gray-100'>Integration with Spectrum 360</span>
        </h2>
        <p className="text-base text-gray-300 md:text-lg">
        Spectra Insight seamlessly integrates with the Spectrum 360 platform, enhancing its core capabilities with powerful AI analysis and recommendations. This integration enables a fluid workflow from assessment through to strategic planning and implementation.
        </p>
      </div>
      
      <div className="grid gap-2  lg:grid-cols-2">
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img1} className=' w-8 lg:w-12'/>
          </div>
          <h6 className="mb-3 text-xl text-white font-bold leading-5">Intuitive Interface</h6>
          <p className="mb-3 text-sm md:text-lg text-gray-300">
          Access AI insights and recommendations through an easy-to-navigate dashboard that simplifies complex data analysis.
          </p>
       
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
         <img src={img2} className=' w-8 lg:w-12'/>
          </div>
          <h6 className="mb-3 text-xl text-white font-bold leading-5">Collaborative Features</h6>
          <p className="mb-3 text-sm md:text-lg text-gray-300">
          Share insights and collaborate with team members directly within the platform, fostering a unified approach to maturity improvement.
          </p>
        
        </div>
      </div>
    
    </div>
    </div>
  )
}
