import React from "react";

import HeroSection2 from "./HeroSection2";
import LastSection from "./LastSection";
import HeroSection3 from './HeroSection3'
import { Fade, Slide } from "react-awesome-reveal";
import Comprehensive from "./Comprehensive";
import Personalized from "./Personalized";
import Section2 from "./Section2Feature";

import Pricing from '../Pricing/index'
import Transforming from "./Transforming";
import Section1 from "./Section1";
import Section3 from "./Section3";
import StepsSection from "./StepsSection";

export default function index() {
  return (
    <div>
      <Fade triggerOnce>
     
        <HeroSection2/>
      {/* <HeroSection3/> */}
      </Fade>
     
      <Comprehensive />
      <Section1/>
      <Section2/>
      <Section3/>
      <StepsSection/>
  
      <LastSection/>
    </div>
  );
}
