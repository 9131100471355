import React, { useRef } from "react";
import img1 from "../../assets/img/Homepage/profile.png";
import img2 from "../../assets/img/Homepage/filter.png";
import img3 from "../../assets/img/Homepage/icons8-collaboration-96.png";
import img4 from "../../assets/img/Homepage/brainstorming.png";
import img5 from "../../assets/img/Homepage/videoconference.png";

const Comprehensive = () => {
  const featureRef = useRef(null);
  return (
    <div
      id="featureSection"
      ref={featureRef}
      class="bg-[#0f172a] py-12 lg:py-20"
    >
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
          <h2 class="text-base font-semibold leading-7 text-blue-100 bg-blue-600 px-3 rounded-xl uppercase mb-4 lg:mb-8">
          Features and Benefits
          </h2>
          <h1 class="lg:text-5xl text-4xl md:text-5xl font-bold tracking-tight text-gray-300 text-center">
            <span class="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-blue-300 to-gray-100">
            Unleashing the Power of AI for Organizational Excellence
            </span>
          </h1>

        
        </div>
        
      </div>
    </div>
  );
};

export default Comprehensive;
