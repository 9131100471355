import React from 'react'
import img1 from "../../assets/img/Homepage/719.jpg";
import img_icon1 from "../../assets/img/Homepage/graph-chart-svgrepo-com.svg";
import img_icon2 from "../../assets/img/Homepage/trend-up-svgrepo-com.svg";

export default function Section3() {
  return (
    <section class="bg-[#0f172a]">
    <div class="container px-6 lg:px-20 py-10 mx-auto">
        <div class="lg:flex lg:items-center place-items-center">
            <div class="w-full space-y-12 lg:w-1/2 ">
                <div>
                    <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white"><span className=" text-white">Benchmarking and Industry Insights</span></h1>

                    <div class="mt-2">
                        <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
                    </div>
                   

                </div>
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2  rounded-xl md:mx-4 text-white bg-blue-500">
                    <img src={img_icon1} className=" w-8 lg:w-10"></img>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Competitive Benchmarking</h1>

                        <p class="mt-3 lg:text-lg text-gray-300">
                        Understand how your organization measures up to peers and industry leaders, with insights that help you carve out a competitive edge.
                        </p>
                    </div>
                </div>

                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2  rounded-xl md:mx-4 text-white bg-blue-500">
                    <img src={img_icon2} className=" w-8 lg:w-10"></img>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Trend Analysis</h1>

                        <p class="mt-3  lg:text-lg text-gray-300">
                        Stay ahead of industry trends with AI-generated reports on emerging practices, technologies, and operational strategies.
                        </p>
                    </div>
                </div>
             

              
            </div>

            <div class="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
                <img class="w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full" src={img1} alt=""/>
            </div>
        </div>

        <hr class="my-12 border-gray-200 dark:border-gray-700"/>

     
    </div>
</section>
  )
}
