import React from "react";
import img1 from "../../assets/img/Homepage/40526.jpg";
import icon1 from "../../assets/img/Homepage/code-svgrepo-com.svg";
import icon2 from "../../assets/img/Homepage/learning-head-book-education-svgrepo-com.svg";
import icon3 from "../../assets/img/Homepage/collaborate-svgrepo-com.svg";
const Section2Feature = () => {
  return (
    <section class="bg-[#0f172a]">
    <div class="container px-6 lg:px-20 py-10 mx-auto">
        <div class="lg:flex lg:items-center place-items-center">

        <div class="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
                <img class="w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full" src={img1} alt=""/>
            </div>
            <div class="w-full space-y-12 lg:w-1/2 ">
                <div>
                    <h1 class="text-2xl font-semibold  capitalize lg:text-4xl text-white"><span className=" text-white">Custom Framework Creation</span> </h1>

                    <div class="mt-2">
                        <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
                    </div>
                   

                </div>
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2  rounded-xl md:mx-4 text-white bg-blue-500">
                    <img src={icon1} className=" w-8 lg:w-20"></img>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold text-white capitalize ">Framework Development</h1>

                        <p class="mt-3 lg:text-lg text-gray-300 dark:text-gray-300">
                        Leverage Spectra Insight's AI capabilities to create or refine your own maturity frameworks. Whether starting from scratch or looking to enhance existing models, Spectra Insight provides the tools and intelligence necessary for development.
                        </p>
                    </div>
                </div>

                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 rounded-xl md:mx-4 text-white bg-blue-500">
                    <img src={icon2} className=" w-8 lg:w-12"></img>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold text-white capitalize ">Adaptive Learning</h1>

                        <p class="mt-3 lg:text-lg text-gray-300 dark:text-gray-300">
                        The AI engine continuously learns from a vast array of industry data, ensuring your frameworks remain cutting-edge and highly relevant.
                        </p>
                    </div>
                </div>

             
            </div>

            
        </div>

        <hr class="my-12 border-gray-700"/>

     
    </div>
</section>
  );
};

export default Section2Feature;
